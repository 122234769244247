<template>
  <router-link :to="derivedTo">
    <slot />
  </router-link>
</template>
<script>
import { mapState } from 'vuex'

import { HASH, ROUTE_COMPONENT, Module, CURRENT_LANGUAGE } from '@/constants'
import { isString, isNil } from '@/helpers'

export default {
  name: ROUTE_COMPONENT,
  props: {
    to: {
      default: HASH,
      type: [String, Object],
    },
  },
  computed: {
    ...mapState(Module.LANGUAGE, [CURRENT_LANGUAGE]),
    derivedTo() {
      if (isString(this.to)) {
        return this.to
      }

      const derivedTo = { ...this.to }
      isNil(derivedTo.params) && (derivedTo.params = {})
      derivedTo.params.lang = this.CURRENT_LANGUAGE

      return derivedTo
    },
  },
}
</script>
