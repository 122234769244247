<template>
  <div :class="gameBoxContainerClass">
    <section v-if="isEmptyBox">
      <div v-if="isCasinoPlatin" class="box casino-game-list-box csn-game-box">
        <img v-if="isHigh" src="@/assets/images/default_gamebox_vertical.jpg" />
        <img v-else src="@/assets/images/default_gamebox_horizontal.png" />
      </div>
      <div v-if="!isCasinoPlatin" class="box casino-game-list-box csn-game-box">
        <img
          src="https://cdn-casino.blackpage.pl/images/logo/casino-five.svg"
        />
      </div>
    </section>
    <section v-else class="csn-game-box-section">
      <div class="csn-game-box-ribbon">
        <span class="csn-game-box-ribbon-hot" v-if="gameData.isHot">
          {{ t('hot') }}
        </span>
        <span class="csn-game-box-ribbon-new" v-else-if="gameData.isNew">
          {{ t('new') }}
        </span>
      </div>
      <div
        v-if="gameImgUrl"
        class="box casino-game-list-box csn-game-box"
        :class="{ 'csn-game-box-gameart': hasGameArtImageClass }"
      >
        <img
          :src="gameImgUrl"
          :alt="gameData.slug"
          @error="replaceByDefault"
          class="a"
        />
      </div>
      <div
        v-else
        class="box casino-game-list-box csn-game-box"
        :class="{ 'csn-game-box-gameart': hasGameArtImageClass }"
      >
        <img
          class="b"
          v-if="isCasinoPlatin && isHigh"
          src="@/assets/images/default_gamebox_vertical.jpg"
        />
        <img
          class="c"
          v-else-if="isCasinoPlatin && !isHigh"
          src="@/assets/images/default_gamebox_horizontal.png"
        />
        <img
          class="d"
          v-else-if="!isCasinoPlatin"
          src="https://cdn-casino.blackpage.pl/images/logo/casino-five.svg"
        />

        <div class="card-body"></div>
      </div>

      <div class="csn-game-box-wrapper-background">
        <div
          class="csn-game-box-wrapper-mobile"
          @click="openGameMenu({ id: gameData.id, slug: gameData.slug })"
        ></div>
      </div>
      <div class="csn-game-box-wrapper">
        <img
          :src="gameData.vendorLogo"
          :alt="vendorLogoAlt"
          class="csn-game-box-vendor-logo"
        />
        <!-- todo: casino-platin different colors for demo and play  -->
        <button
          v-if="gameData.hasPlayButton"
          class="btn casino-btn csn-game-box-wrapper-play-btn casino-btn-theme-based"
          @click="handlePlayClick"
        >
          {{ t('play_now') }}
        </button>
        <button
          v-if="gameData.hasDemoButton"
          class="btn casino-btn csn-game-box-wrapper-demo-btn casino-btn-default"
          @click="handleDemoClick"
        >
          {{ t('try_me') }}
        </button>
        <div class="csn-game-box-rating">
          <Rating :value="gameData.rating" />
        </div>
      </div>
      <Route
        :to="{ name: gameInfoRoute, params: { slug: gameData.slug } }"
        class="csn-game-box-game-label-route"
      >
        <h4 class="my-0 font-weight-normal game-title csn-game-box-game-label">
          {{ gameData.name }}
        </h4>
      </Route>
    </section>
  </div>
</template>
<script>
import { mapMutations } from 'vuex'

import Route from '../Route'

import {
  RouteName,
  GAME_BOX_WITH_HOVER,
  EMPTY_STRING,
  SET_SIDE_MENU_GAME,
  Module,
  Digit,
  CASINO_PLATIN,
  DefaultImage,
} from '@/constants'
import { pipe, navigateTo, when, isNotNil } from '@/helpers'
import * as Game from '@/models/getters/game'

const gameBoxContainerClass =
  'card shadow-sm game-container csn-game-box-container csn-game-box-container-with-hover'
const gameBoxHighClass = 'csn-game-box-container-high'
const VENDOR_LOGO = 'vendor logo'
const GAMEART = 'gameart'

export default {
  name: GAME_BOX_WITH_HOVER,
  components: {
    Rating: () => import('@/components/Rating'),
    Route,
  },
  data: () => ({
    gameImgUrl: null,
  }),
  props: {
    game: Object,
    isHigh: Boolean,
  },
  computed: {
    gameData() {
      return {
        id: Game.getId(this.game),
        name: Game.getName(this.game),
        vendorLogo: Game.getVendorLogo(this.game),
        slug: Game.getUrlSlug(this.game),
        hasPlayButton: Game.hasPlay(this.game),
        hasDemoButton: Game.hasDemo(this.game),
        isHot: Game.isHot(this.game),
        isNew: Game.isNew(this.game),
        rating: Game.getRating(this.game),
      }
    },
    gameBoxContainerClass() {
      return [
        gameBoxContainerClass,
        this.isHigh ? gameBoxHighClass : EMPTY_STRING,
      ]
    },
    t() {
      return this.$createComponentTranslator(GAME_BOX_WITH_HOVER)
    },
    isEmptyBox() {
      return !this.gameData.name || !this.gameData.slug
    },
    vendorLogoAlt: () => VENDOR_LOGO,
    hasGameArtImageClass() {
      return (
        !this.isHigh &&
        this.game.urlSlug.slice(Digit.NOUGHT, Digit.SEVEN) === GAMEART
      )
    },
    isCasinoPlatin: () => process.env.VUE_APP_THEME === CASINO_PLATIN,
    gameInfoRoute: () => RouteName.GAME_INFO,
  },
  watch: {
    game: {
      immediate: true,
      deep: true,
      handler(game) {
        this.gameImgUrl = pipe(Game.getImage, when(isNotNil, encodeURI))(game)
      },
    },
  },
  methods: {
    ...mapMutations(Module.MAIN, [SET_SIDE_MENU_GAME]),
    handlePlayClick() {
      navigateTo({
        name: RouteName.GAME_PLAY,
        params: { slug: this.gameData.slug },
      })
    },
    handleDemoClick() {
      navigateTo({
        name: RouteName.GAME_DEMO,
        params: { slug: this.gameData.slug },
      })
    },
    openGameMenu(value) {
      this.SET_SIDE_MENU_GAME(value)
    },
    replaceByDefault(e) {
      e.target.src = DefaultImage[process.env.VUE_APP_THEME]
    },
  },
}
</script>
